
<h3 class="form-section">
    {{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.month related' | translate }}
    <af-help helpid="operation.project-task-product-rate-summary.project-task-related"></af-help>
</h3>

<table class="table table-striped table-bordered table-hover mt-3 mb-3" *ngIf="projectTaskProductRates && projectTaskProductRates.length > 0">
    <thead>
        <tr>
            <th>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.code' | translate }}</th>
            <th>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.category_item' | translate }}</th>
            <th>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.text for invoice' | translate }}</th>
            <th>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.amount' | translate }}</th>
            <th>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.rt' | translate }}</th>
            <th>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.rate' | translate }}</th>
            <th>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.total without vat' | translate }}</th>
            <th>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.additional information for invoice' | translate }}</th>
            <th style="width: 60px;">{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.rate approved' | translate }}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let rate of projectTaskProductRates; let $index = index" [class.table-warning]="rate.amount == 0">
            <td>
                <a [routerLink]="['/finance/product-rate', rate.productRateId]">{{rate.productRateCode}}</a>
            </td>
            <td>
                {{ rate.productCategoryTitle }} / {{ rate.productTitle }}
                <span *ngIf="rate.title"> - {{ rate.title }}</span>
            </td>
            <td>
                {{ rate.productRateInvoiceText }}
            </td>
            <td style="max-width: 100px;">
                <ng-container *ngIf="!edit">{{ rate.amount }}</ng-container>
                <input *ngIf="edit" type="text" [disabled]="rate.isCopied" class="form-control" [ngModel]="rate.amount" (ngModelChange)="rate.amount = $event; updatePriceTotal(rate)" [name]="'projectTaskProductRatesAmount' + $index" afNumber />
                <div *ngIf="rate.copiedText">
                    {{rate.copiedText}}
                    <af-help class="help-static" helpid="operation.project-task-product-rate-summary.product-rate-copied"></af-help>
                </div>
            </td>
            <td>
                {{ rate.rateContractTypeTitle }}
            </td>
            <td align="right" style="max-width: 100px;">
                <ng-container *ngIf="!(edit && rate.isVariable)">{{ rate.price | afCurrency:rate.currency:'1.2-9' }}</ng-container>
                <input *ngIf="edit && rate.isVariable" afNumber type="text" class="form-control"
                       id="projectTaskProductRatesPrice{{$index}}" name="projectTaskProductRatesPrice{{$index}}"
                       [ngModel]="rate.price"
                       (ngModelChange)="onRatePriceChange(rate, $event)" />
            </td>
            <td align="right">
                <b>{{ rate.priceTotal | afCurrency:rate.currency:'1.2-9' }}</b>
            </td>
            <td>
                <div *ngIf="!edit" style="white-space: pre-line;">{{ rate.invoiceText }}</div>
                <input *ngIf="edit" type="text" class="form-control" [(ngModel)]="rate.invoiceText" [name]="'projectTaskProductRatesInvoiceText' + $index" />
            </td>
            <td>
                <div class="form-check">
                    <input type="checkbox" [disabled]="true" class="form-check-input" [checked]="rate.isApproved">
                    <label class="form-check-label"></label>
                </div>
            </td>
        </tr>
    </tbody>
    <tfoot *ngIf="projectTaskProductRatesTotalCurrency && projectTaskProductRatesTotalCurrency != '-'">
        <tr>
            <td colspan="6"><b>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.total without vat' | translate }}</b></td>
            <td align="right">
                <b>{{ projectTaskProductRatesTotal | afCurrency:projectTaskProductRatesTotalCurrency:'1.2-9' }}</b>
            </td>
        </tr>
    </tfoot>
</table>

<af-note *ngIf="projectTaskProductRates && projectTaskProductRatesTotalCurrency == '-'" type="danger">
    <span>{{ 'table.cannot show total for rates with different currencies.' | translate }}</span>
</af-note>

<af-note *ngIf="projectTaskProductRates && projectTaskProductRates.length === 0" type="info">
    <span>{{ 'table.no records found' | translate }}</span>
</af-note>

<h3 class="form-section">
    {{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.from work reports' | translate }}
    <af-help helpid="operation.project-task-product-rate-summary.work-report-product-rates"></af-help>
</h3>

<table class="table table-striped table-bordered table-hover mt-3 mb-3" *ngIf="workReportProductRates && workReportProductRates.length > 0">
    <thead>
        <tr>
            <th *ngIf="hasBonuses">
                <a (click)="toggleShowAllBonuses()">
                    <i class="fa" [ngClass]="showAllBonuses ? 'fa-minus-square' : 'fa-plus-square'"></i>
                </a>
            </th>
            <th>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.code' | translate }}</th>
            <th>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.category_item' | translate }}</th>
            <th colspan="2">{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.bonuses' | translate }}</th>
            <th>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.amount' | translate }}</th>
            <th>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.rt' | translate }}</th>
            <th>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.rate' | translate }}</th>
            <th>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.total without vat' | translate }}</th>
            <th>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.additional information for invoice' | translate }}</th>
            <th style="width: 60px;">{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.rate valid to' | translate }}</th>
            <th style="width: 60px;">{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.rate approved' | translate }}</th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let rate of workReportProductRates; let $index = index">
            <tr [class.table-warning]="rate.amount == 0" [class.table-danger]="rate.isNotInProjectTask">
                <td *ngIf="hasBonuses" [attr.rowspan]="getRowSpan(rate)">
                    <a *ngIf="rate.bonuses?.length > 0" (click)="toggleShowBonuses(rate)">
                        <i class="fa" [ngClass]="rate.showBonuses ? 'fa-minus-square' : 'fa-plus-square'"></i>
                    </a>
                </td>
                <td [attr.rowspan]="getRowSpan(rate)">
                    <a [routerLink]="['/finance/product-rate', rate.productRateId]">{{rate.productRateCode}}</a>
                </td>
                <td [attr.rowspan]="getRowSpan(rate)">
                    {{ rate.productCategoryTitle }} / {{ rate.productTitle }}
                    <span *ngIf="rate.title"> - {{ rate.title }}</span>
                    <i *ngIf="rate.isNotInProjectTask" class="fa fa-exclamation-triangle" style="color:red"
                       [ngbTooltip]="isNotInProjectTaskTooltipText"></i>
                </td>
                <ng-container *ngIf="!(rate.showBonuses && rate.bonuses?.length > 0)">
                    <td colspan="2"></td>
                    <td align="right">
                        {{ rate.amount | number:'1.2-2' }}
                    </td>
                    <td>
                        {{ rate.rateContractTypeTitle }}
                    </td>
                    <td align="right" style="max-width: 100px;">
                        <ng-container *ngIf="rate.bonuses?.length > 0">{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.from' | translate }}</ng-container>
                        {{ rate.price | afCurrency:rate.currency:'1.2-9' }}
                    </td>
                    <td align="right">
                        <b>{{ rate.priceTotal | afCurrency:rate.currency:'1.2-9' }}</b>
                    </td>
                </ng-container>
                <ng-container *ngIf="rate.showBonuses && rate.bonuses?.length > 0">
                    <td colspan="6" style="padding: 0"></td>
                </ng-container>

                <td [attr.rowspan]="getRowSpan(rate)">
                    <div *ngIf="!edit" style="white-space: pre-line;">{{ rate.invoiceText }}</div>
                    <input *ngIf="edit"
                           type="text" class="form-control"
                           [(ngModel)]="rate.invoiceText"
                           [name]="'workReportProductRatesInvoiceText' + $index" />
                </td>
                <td [attr.rowspan]="getRowSpan(rate)">
                    {{ rate.productRateValidTo | afDate:'L' }}
                </td>
                <td [attr.rowspan]="getRowSpan(rate)">
                    <div class="form-check">
                        <input type="checkbox" [disabled]="true" class="form-check-input" [checked]="rate.isApproved">
                        <label class="form-check-label"></label>
                    </div>
                </td>
            </tr>
            <ng-container *ngIf="rate.showBonuses && rate.bonuses?.length > 0">
                <tr [class.table-danger]="rate.isNotInProjectTask" *ngFor="let bonus of rate.bonuses">
                    <td>
                        <i *ngIf="bonus.type === 1" class="fa fa-sun"></i>
                        <i *ngIf="bonus.type === 2" class="icomoon-icons_moon" style="font-size: inherit;"></i>
                        <i *ngIf="bonus.type === 3" class="icomoon-icons_umbrella" style="font-size: inherit;"></i>
                        <i *ngIf="bonus.type === 4" class="icomoon-icons_church" style="font-size: inherit;"></i>
                        <i *ngIf="bonus.type === 5" class="icomoon-icons_confetti" style="font-size: inherit;"></i>
                    </td>
                    <td>
                        {{ bonus.percent / 100 | percent }}
                    </td>
                    <td align="right">
                        {{ bonus.amount | number:'1.2-2' }}
                    </td>
                    <td>
                        {{ rate.rateContractTypeTitle }}
                    </td>
                    <td align="right" style="max-width: 100px;">
                        {{ bonus.price | afCurrency:rate.currency:'1.2-9' }}
                    </td>
                    <td align="right">
                        {{ bonus.amount * bonus.price | afCurrency:rate.currency:'1.2-9' }}
                    </td>
                </tr>
                <tr [class.table-danger]="rate.isNotInProjectTask">
                    <td colspan="2">
                        <b>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.total with bonuses' | translate }}</b>
                    </td>
                    <td align="right">
                        {{ rate.amount | number:'1.2-2' }}
                    </td>
                    <td>
                        {{ rate.rateContractTypeTitle }}
                    </td>
                    <td align="right" style="max-width: 100px;">
                        <ng-container *ngIf="rate.bonuses?.length > 0"></ng-container>
                        <ng-container *ngIf="!(rate.bonuses?.length > 0)">{{ rate.price | afCurrency:rate.currency:'1.2-9' }}</ng-container>
                    </td>
                    <td align="right">
                        <b *ngIf="rate.bonuses?.length > 0">{{ rate.priceTotal | afCurrency:rate.currency:'1.2-9' }}</b>
                        <b *ngIf="!(rate.bonuses?.length > 0)">{{ rate.amount * rate.price | afCurrency:rate.currency:'1.2-9' }}</b>
                    </td>
                </tr>
            </ng-container>
        </ng-container>
    </tbody>
    <tfoot *ngIf="workReportProductRatesTotalCurrency && workReportProductRatesTotalCurrency != '-'">
        <tr>
            <td [attr.colspan]="hasBonuses?8:7"><b>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.total without vat' | translate }}</b></td>
            <td align="right">
                <b>{{ workReportProductRatesTotal | afCurrency:workReportProductRatesTotalCurrency:'1.2-9' }}</b>
            </td>
        </tr>
    </tfoot>
</table>

<af-note *ngIf="workReportProductRates && workReportProductRatesTotalCurrency == '-'" type="danger">
    <span>{{ 'table.cannot show total for rates with different currencies.' | translate }}</span>
</af-note>

<af-note *ngIf="workReportProductRates && workReportProductRates.length === 0" type="info">
    <span>{{ 'table.no records found' | translate }}</span>
</af-note>

<h3 class="form-section">{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.total for project task' | translate }}</h3>

<table class="table table-striped table-bordered table-hover mt-3 mb-3" *ngIf="productRates && productRates.length > 0 && productRatesTotalCurrency && productRatesTotalCurrency != '-'">
    <tfoot>
        <tr>
            <td width="90%"><b>{{ 'hollen.clientapp.src.app.operation.project-task-product-rate.project-task-product-rate-summary.component.total without vat for project task' | translate }}</b></td>
            <td width="10%" align="right">
                <b>{{ productRatesTotal | afCurrency:productRatesTotalCurrency:'1.2-9' }}</b>
            </td>
        </tr>
    </tfoot>
</table>

<af-note *ngIf="productRatesTotalCurrency == '-'" type="danger">
    <span>{{ 'table.cannot show total for rates with different currencies.' | translate }}</span>
</af-note>

