import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { AppService } from "../../app.service";

@Component({
    selector: "op-project-task-product-rate-summary",
    templateUrl: "./project-task-product-rate-summary.component.html"
})
export class ProjectTaskProductRateSummaryComponent implements OnInit {
    @Input() productRates: app.operation.ProjectTaskProductRateAmountViewModel[];
    @Input() edit: boolean;
    showAllBonuses = false;
    hasBonuses: boolean;
    isNotInProjectTaskTooltipText: string;
    currentUser: app.UserViewModel;

    constructor(
        private appService: AppService) {  
        this.currentUser = appService.homeViewModel.user;
        this.isNotInProjectTaskTooltipText = this.currentUser.language === "sk" ? "Podzákazka obsahuje sortovania ktoré majú sadzbu mimo dátumu platnosti, sadzbu viazanú na diel, sadzbu viazanú na podzákazku alebo daná sadzba nebola nájdená na podzákazke." : "The projecttask contains workreports that have a rate outside the validity date, a rate tied to a part, a rate tied to a projecttask, or the given rate was not found on the projecttask.";
    }

    ngOnInit() {
        this.hasBonuses = this.productRates.find(i => i.bonuses && i.bonuses.length > 0) != null;
    }

    get productRatesTotal() {
        if (!this.productRates) {
            return 0;
        }
        return this.productRates.map((x) => x.priceTotal).reduce((a, b) => a + b, 0);
    }

    get productRatesTotalCurrency() {
        if (!this.productRates) {
            return "";
        }
        return this.productRates.map((x) => x.currency).reduce((a, b) => a === null ? b : (a == b ? a : "-"), null);
    }
    get currency() {
        if (!this.productRates) {
            return "";
        }

        return this.productRates[0].currency;
    }

    get projectTaskProductRates() {
        if (!this.productRates) {
            return [];
        }

        return this.productRates.filter(x => x.isProjectTaskRelated);
    }

    get projectTaskProductRatesTotal() {
        return this.projectTaskProductRates.map((x) => x.priceTotal).reduce((a, b) => a + b, 0);
    }

    get projectTaskProductRatesTotalCurrency() {
        return this.projectTaskProductRates.map((x) => x.currency).reduce((a, b) => a === null ? b : (a == b ? a : "-"), null);
    }

    get workReportProductRates() {
        if (!this.productRates) {
            return [];
        }

        return this.productRates.filter(x => !x.isProjectTaskRelated);
    }

    get workReportProductRatesTotal() {
        return this.workReportProductRates.map((x) => x.priceTotal).reduce((a, b) => a + b, 0);
    }

    get workReportProductRatesTotalCurrency() {
        return this.workReportProductRates.map((x) => x.currency).reduce((a, b) => a === null ? b : (a == b ? a : "-"), null);
    }

    onRatePriceChange(rate: app.operation.ProjectTaskProductRateAmountViewModel, newPrice: string) {
        if (newPrice != null) {
            rate.price = parseFloat(newPrice);
        } else {
            rate.price = null;
        }
        this.updatePriceTotal(rate);
    }

    updatePriceTotal(rate: app.operation.ProjectTaskProductRateAmountViewModel) {
        rate.priceTotal = (rate.price || 0) * (rate.amount || 0);
    }

    getRowSpan = (item: app.operation.ProjectTaskProductRateAmountViewModel) => {
        if (item.showBonuses && item.bonuses && item.bonuses.length > 0) {
            return item.bonuses.length + 2;
        }
        return 1;
    }

    toggleShowBonuses(item: app.operation.ProjectTaskProductRateAmountViewModel) {
        item.showBonuses = !item.showBonuses;
    }

    toggleShowAllBonuses() {
        this.showAllBonuses = !this.showAllBonuses;
        this.workReportProductRates.forEach(x => x.showBonuses = this.showAllBonuses);
    }
}
